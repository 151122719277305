import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./billing.module.scss";
import { useNavigate } from "react-router-dom";
import CustomInput, {
  CustomButton,
  CustomSelectOne,
  Toastify,
} from "../../components/Custom";
import axios from "axios";
import { BACKENDURL } from "../../assets/data/constant";
import { GlobalContext } from "../../context/globalContext";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import html2canvas from "html2canvas";

const Billing = () => {
  const navigate = useNavigate();
  const { email, token, searchQuery, setSearchQuery } =
    useContext(GlobalContext);
  const [allInventory, setAllInventory] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState("");
  const [increaseInventory, setIncreaseInventory] = useState(false);
  const [inventoryForm, setInventoryForm] = useState(false);
  const [cart, setCart] = useState([]);
  const [finalCart, setFinalCart] = useState({ totalAmount: "0.00" });

  // temp print

  const receiptRef = useRef();

  const handlePrint = () => {
    html2canvas(receiptRef.current).then((canvas) => {
      const image = canvas.toDataURL("image/png");

      // Create an iframe
      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.width = "0px";
      iframe.style.height = "0px";
      iframe.style.border = "none";
      document.body.appendChild(iframe);

      // Write the image inside the iframe
      iframe.contentDocument.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            @media print {
              body { margin: 0; }
              img { width: 100%; height: auto; }
            }
          </style>
        </head>
        <body>
          <img src="${image}" onload="window.print(); window.onafterprint = () => document.body.removeChild(this.parentElement);">
        </body>
      </html>
    `);
      iframe.contentDocument.close();
    });
  };

  //functions

  function getAllInventory() {
    axios
      .get(BACKENDURL + `/inventory`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setAllInventory(response?.data?.data);
        console.log(response, "all inventory response");
      })
      .catch((error) => {
        console.log(error, "all inventory error");
        Toastify(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          "error",
          "error"
        );
      });
  }

  function updateInventoryItem() {
    inventoryForm.medicineName = selectedMedicine;
    axios
      .put(BACKENDURL + "/inventory/update", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: inventoryForm,
      })
      .then((response) => {
        setIncreaseInventory(false);
        if (response?.data?.status) {
          window?.location?.reload();
          Toastify(response?.data?.message, "success");
        } else {
          Toastify(response?.data?.message, "error");
        }
        console.log(response, "Update inventory response");
      })
      .catch((error) => {
        console.log(error, "Update inventory error");
        Toastify(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          "error",
          "error"
        );
      });
  }

  const addToCart = (product) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find(
        (item) => item.item === product._id
      );
      if (existingProduct) {
        return prevCart.map((item) =>
          item.item === product._id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [
          ...prevCart,
          {
            item: product._id,
            quantity: 1,
            price: product.retailPrice,
            name: product.medicineName,
            mrp: product.mrp,
          },
        ];
      }
    });
  };

  const increaseQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.item === productId
          ? { ...item, quantity: item.quantity + 1 }
          : item
      )
    );
  };

  const decreaseQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart
        .map((item) =>
          item.item === productId && item.quantity > 1
            ? { ...item, quantity: item.quantity - 1 }
            : item
        )
        .filter((item) => item.quantity > 0)
    );
  };

  const removeFromCart = (productId) => {
    setCart((prevCart) => prevCart.filter((item) => item.item !== productId));
  };

  const calculateTotalCost = (items) => {
    return items.reduce((total, item) => {
      return total + item.quantity * parseFloat(item.price);
    }, 0);
  };

  const removeFields = (items) => {
    return items.map(({ name, mrp, ...rest }) => rest);
  };

  async function submitBill() {
    const updatedCartItems = removeFields(cart);
    finalCart.totalAmount = calculateTotalCost(cart);
    finalCart.items = updatedCartItems;

    try {
      let response = await axios.post(
        BACKENDURL + "/inventory/billing",
        finalCart,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response, "response cart added!");
      if (response?.data?.status) {
        Toastify("Bill Successful!", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        Toastify(response?.data?.message, "error");
      }
    } catch (error) {
      Toastify("Server error while submitting bill", "error");
      console.log(error?.message, "Server error while submitting bill");
    }

    // handlePrint();
  }

  //renderings

  useEffect(() => {
    getAllInventory();
  }, []);

  return (
    <div className={classNames.inventory}>
      <div className={classNames.productsContainer}>
        <div className={classNames.topBar}>
          <div className={classNames.title}>All Items</div>
        </div>
        <div className={classNames.allProducts}>
          {Array.isArray(allInventory) &&
            allInventory.length > 0 &&
            allInventory
              .filter((eachinventory) => {
                let searchText = searchQuery?.toLowerCase();
                return eachinventory?.medicineName
                  ?.toLowerCase()
                  ?.includes(searchText);
              })
              .map((eachProduct) => {
                const cartItem = cart.find(
                  (item) => item.item === eachProduct._id
                );

                return (
                  <div className={classNames.eachProduct} key={eachProduct._id}>
                    <div className={classNames.detailsContainer}>
                      <div className={classNames.imageContainer}>
                        {eachProduct.medicineName.charAt(0)}
                      </div>
                      <div className={classNames.details}>
                        <div className={classNames.name}>
                          <span>{eachProduct.medicineName}</span>{" "}
                          <span>( {eachProduct.quantity} items available)</span>
                        </div>
                        <div className={classNames.price}>
                          <span>₹ {eachProduct.retailPrice}</span>
                          <span>₹ {eachProduct.mrp}</span>
                        </div>
                      </div>
                    </div>
                    <div className={classNames.controlBtns}>
                      <FaCircleMinus
                        onClick={() => decreaseQuantity(eachProduct._id)}
                      />
                      <input
                        type="text"
                        value={cartItem?.quantity || 0}
                        readOnly
                      />
                      <FaCirclePlus
                        className={
                          eachProduct.quantity <= cartItem?.quantity &&
                          classNames.notAllowedBtn
                        }
                        onClick={() => addToCart(eachProduct)}
                      />
                    </div>
                  </div>
                );
              })}
        </div>
        <div className={classNames.totalBill}>
          <div className={classNames.header}>
            <div className={classNames.title}>
              <div>Order Summary</div>
              <div>(Total Amount)</div>
            </div>
          </div>
          <div className={classNames.price}>
            ₹{" "}
            {calculateTotalCost(cart)
              ? calculateTotalCost(cart)?.toFixed(2)
              : "0.00"}
          </div>
        </div>
      </div>
      <div className={classNames.cartContainer}>
        <div className={classNames.title}>
          Cart
          <div className={classNames.paymentType}>
            <CustomSelectOne
              stateValue={finalCart}
              setState={setFinalCart}
              name="billingType"
              allOptions={[{ name: "Online" }, { name: "Cash" }]}
            />
          </div>
        </div>
        <div className={classNames.inputDiv}>
          <CustomInput
            placeHolder="Enter name..."
            name="name"
            stateValue={finalCart}
            setState={setFinalCart}
          />
          <CustomInput
            placeHolder="Enter phone number..."
            name="phoneNumber"
            stateValue={finalCart}
            setState={setFinalCart}
          />
        </div>
        <div className={classNames.cartItems}>
          {Array.isArray(cart) &&
            cart?.length > 0 &&
            cart?.map((eachCartItem) => {
              return (
                <div className={classNames.eachProduct} key={eachCartItem.item}>
                  <div className={classNames.detailsContainer}>
                    <div className={classNames.imageContainer}>
                      {eachCartItem.name.charAt(0)}
                    </div>
                    <div className={classNames.details}>
                      <div className={classNames.name}>{eachCartItem.name}</div>
                      <div className={classNames.price}>
                        <span>₹ {eachCartItem.price}</span>
                        <span>₹ {eachCartItem.mrp}</span>
                      </div>
                    </div>
                  </div>
                  <div className={classNames.cartBtns}>
                    <div className={classNames.eachTotal}>
                      <div>{eachCartItem?.quantity} Quantity</div>
                      <div>
                        ₹{" "}
                        {(
                          eachCartItem?.quantity * eachCartItem?.price
                        )?.toFixed(2)}
                      </div>
                    </div>
                    <div
                      className={classNames.deleteBtn}
                      onClick={() => removeFromCart(eachCartItem?.item)}
                    >
                      <MdDelete />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div
          className={`${classNames.paymentBtn} ${
            !(
              Array.isArray(cart) &&
              cart?.length > 0 &&
              finalCart?.billingType
            ) && classNames.notAllowedBtn
          }`}
          onClick={submitBill}
        >
          Confirm Payment
        </div>
        {/* <Receipt
          receiptRef={receiptRef}
          cartItems={cart}
          totalAmount={calculateTotalCost(cart)}
        /> */}
      </div>
    </div>
  );
};

export default Billing;

const Receipt = ({ receiptRef, cartItems, totalAmount }) => {
  return (
    <div className={classNames.printDiv}>
      {/* Printable Receipt */}
      <div
        ref={receiptRef}
        id="receipt"
        style={{
          width: "58mm",
          fontFamily: "monospace",
          padding: "10px",
          textAlign: "center",
        }}
      >
        {/* Logo */}
        <div
          style={{ textAlign: "center", fontSize: "1rem", fontWeight: "550" }}
        >
          Zafa Pet Care
        </div>

        <br />
        {/* Date */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p style={{ textAlign: "left", fontSize: "11px" }}>
            <span style={{ fontWeight: "550" }}>Date:</span>
            {new Date().toLocaleDateString()}
          </p>
          <p style={{ textAlign: "left", fontSize: "11px" }}>
            <span style={{ fontWeight: "550" }}>Place:</span>Paramakudi
          </p>
        </div>
        <br />

        {/* Items List */}
        <table
          className={classNames.printTable}
          style={{ width: "100%", fontSize: "12px" }}
        >
          <thead className={classNames.printHead}>
            <tr>
              <th>Description</th>
              <th>Qty</th>
              <th>Rate</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody className={classNames.printBody}>
            {cartItems.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>₹{item.price}</td>
                <td>₹{item.quantity * item.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Total */}
        <p
          className={classNames.netAmount}
          style={{
            fontSize: "0.8rem",
            fontWeight: "550",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>Net Amount</span>
          <span>₹{totalAmount}</span>
        </p>
        <br />
        <p className={classNames.finalMessageBold}>Have a Nice Day</p>
        <p className={classNames.finalMessage}>Thanks for your vist</p>
      </div>
    </div>
  );
};
